<template>
  <div class="body">
    <section class="banner">
      <h2>矩阵<strong>工厂</strong></h2>
      <img class="img" src="../../assets/v3/industrial/banner.png" width="1920" height="730" />
      <p>矩阵有<strong>最完整的工业 4.0解决方案</strong><br />通过数字化技术大幅提高企业<br class="mShow" />应对内部、外部变化的响应能力<br />将<strong>智能传感器</strong>、<strong>分析技术</strong><br class="mShow" />和<strong>人工智能</strong>结合起来<span class="mHide">，</span><br class="mShow" />实时监控运营和潜在的中断<br />使生产保持在最优级别运行</p>
    </section>

    <section class="box factory">
      <div class="layout">
        <img class="map" src="../../assets/v3/industrial/plan.png" width="898" height="725" />
        <div class="summary">
          <h3>矩阵智能工厂的<strong>价值创造</strong>与<strong>生产</strong></h3>
          <p>我们的所有设施实现碳中和。在致力于达到清洁 Smart Factory（智能工厂）的路上，我们在各个领域尝试利用最新技术。践行循环经济原理和符合环境标准的供应链，进而完善我们的可持续发展战略。</p>
          <img class="img" src="../../assets/v3/industrial/factory.jpg" width="726" height="407" />
        </div>
      </div>
    </section>

    <section class="box intro">
      <div class="layout">
        <h3>我们是一家拥有<strong>整车制造能力</strong>的<strong>智能驾驶企业</strong></h3>
        <p>橙仕集团在山东设有占地500多亩的智能汽车生产基地【矩阵-豪驰基地】，四大工艺齐全，具备年产10万台的工业制造能力。</p>
        <ul class="workshops">
          <li>
            <div class="con">
              <h4>冲压车间</h4>
              <p>采用更先进的冲压设备和模具供应商自动化率达到100%。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_1.jpg" width="980" height="418" />
            </div>
          </li>
          <li>
            <div class="con">
              <h4>焊装车间</h4>
              <p>采用包含激光焊接在内的10种焊接工艺点焊、铆接自动化率达到100%。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_2.jpg" width="980" height="418" />
            </div>
          </li>
          <li>
            <div class="con">
              <h4>涂装车间</h4>
              <p>使用更高质量的涂料供应商，喷涂自动化率100%。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_3.jpg" width="980" height="418" />
            </div>
          </li>
          <li>
            <div class="con">
              <h4>总装车间</h4>
              <p>采用多种自动化安装设备，实时监测，保证快速准确的装配。</p>
            </div>
            <div class="preview">
              <img class="img" src="../../assets/v3/industrial/workshop_4.jpg" width="980" height="418" />
            </div>
          </li>
        </ul>
      </div>
    </section>
    
    <section class="box bannerEnd">
      <div class="layout">
        <h3>环境的<strong>可持续性</strong>和<strong>责任感</strong></h3>
        <p>除了在质量、技术专长、性能和竞争力方面保持高标准之外，<br class="mHide" />矩阵还致力于要求供应商严格遵守可持续性发展要求。</p>
      </div>
      <img class="img" src="../../assets/v3/industrial/bannerEnd.jpg" width="1920" height="1284" />
    </section>
  </div>
</template>
<script>
export default {
  name: "Factory",
};
</script>
<style lang="scss" scoped>
.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 280px;
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  background-color: #e83e0b;

  h2 {
    font-size: 100px;
    line-height: 120px;
    color: #fff;
  }

  p {
    position: relative;
    height: 264px;
    margin-top: -264px;
    z-index: 5;
  }

  .img {
    position: relative;
    margin-top: -20px;
    z-index: 1;
  }
}

.layout {
  position: relative;
  max-width: 1440px;
}


.box {
  font-size: 18px;
  line-height: 28px;

  &::before {
    position: absolute;
    width: 12px;
    height: 175px;
    content: '';
    background-color: #ff3f00;
  }

  h3 {
    font-size: 50px;
    line-height: 60px;
    font-style: italic;
  }
}

.factory {
  padding: 180px 0 145px;
  background-color: #222226;

  &::before {
    right: 0;
  }

  .map {
    position: absolute;
    top: -82px;
    left: -240px;
  }

  .summary {
    margin-left: 675px;
    color: #fff;

    h3 {
      color: #fff;
    }

    p {
      margin: 12px 0 16px;
      text-indent: 2em;
    }

    .img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

.intro {
  padding: 160px 0;

  &::before {
    left: 0;
  }

  h3 {
    margin-bottom: 8px;
  }

  .workshops {
    margin-top: 32px;

    li {
      display: flex;
      flex-direction: row-reverse;

      & + li {
        margin-top: 10px;
      }

      &:nth-child(2n) {
        flex-direction: row;

        .con {
          margin-left: 0;
          margin-right: 10px;
        }
      }

      .con {
        display: flex;
        padding: 50px;
        margin-left: 10px;
        flex-direction: column;
        justify-content: flex-end;
        width: 410px;
        color: #fff;
        background-color: #222226;

        h4 {
          font-size: 30px;
          color: #fff;
          line-height: 48px;
        }
      }

      .preview {
        flex: 1;

        .img {
          display: block;
          width: 100%;
          height: auto;
          min-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.bannerEnd {
  &::before {
    display: none;
  }

  .layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 540px;
  }

  h3 {
    margin-bottom: 16px;
  }

  .img {
    display: block;
    margin-top: -540px;
    width: 100%;
    height: auto;
    min-height: 560px;
    object-fit: cover;
  }
}


@media only screen and (max-width: 500px) {
  .banner {
    padding-top: 180px;
    height: 640px;
    font-size: 14px;
    line-height: 20px;

    h2 {
      margin-bottom: 40px;
      font-size: 50px;
      line-height: 60px;
    }

    p {
      margin-top: -20px;
      height: auto;
      padding: 0 20px;
    }

    .img {
      margin: 0;
      width: 100%;
      height: auto;
    }
  }

  .box {
    font-size: 14px;
    line-height: 20px;

    &::before {
      display: none;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .factory {
    padding: 0;

    .map {
      position: static;
      display: block;
      width: 100%;
      height: auto;
    }

    .summary {
      margin-left: 0;
      padding: 24px;

      p {
        margin: 8px 0 12px;
      }
    }
  }

  .intro {
    padding: 24px;

    p {
      text-indent: 2em;
    }

    .workshops {
      margin-top: 12px;

      li {
        flex-direction: column-reverse;

        & + li {
          margin-top: 12px;
        }

        &:nth-child(2n) {
          flex-direction: column-reverse;

          .con {
            margin: 0;
          }
        }

        .con {
          display: flex;
          padding: 12px 16px;
          margin-left: 0;
          width: auto;

          h4 {
            margin-bottom: 4px;
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
          }

          p {
            text-indent: 0;
          }
        }
      }
    }
  }

  .bannerEnd {
    .layout {
      display: block;
      margin: 0;
      padding: 24px;
      height: 260px;
      max-width: 80%;
    }

    h3 {
      margin-bottom: 8px;
    }

    .img {
      margin-top: -260px;
      min-height: 260px;
    }
  }
}

</style>